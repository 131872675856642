
.Heading{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
*
{
    font-family: 'Roboto', sans-serif;
}

.How_footer{
     /* border: 3px darkblue solid; */
     display: flex;
     margin-top: 50px;
}

.you {
    margin-top: 20px;
    margin-right: 20px;
}
.yes {
    margin-top: 20px;
    margin-left: 20px;
}




.How_post{
    /* border: 3px darkslategrey solid;    */
    text-align: center;
}
.How_get{
    text-align: center;
}
.How_deploy{
    text-align: center;
}
.How_support{
    text-align: center;
}

.How_post h2,.How_get h2 , .How_deploy h2 , .How_support h2{
    margin-top: 16px;
}


@media screen and (max-width:786px) {
    .How_footer {
        flex-direction: column;
    }
    #btnn_how{
        width: 115px;
        margin-right: 10px;
        padding-left: 25px;
    }
}




.How_padding{
    margin-top: 40px;
    text-align: center;
    justify-content: center;
    padding: 15px;
}

.How_icon{
    margin-top: 30px;
}


@media screen and (max-width:786px)
{
    .Heading {
        flex-direction: column;
        text-align: center;
    }
    .hello {
        text-align: -webkit-center;
        margin-bottom: 20px;
    }
}
