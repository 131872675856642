.footercontainer {
    text-align: center;
    background-color: #24273d;
    width: 100%;
    padding: 80px 60px 60px 60px;
}
*
{
    font-family: 'Roboto', sans-serif;
}


.col-md-3 .col-sm-3 {
    text-align: center;
}

h4 {
    color: white;
}

.row {
    justify-content: center;
    margin: 0%;
    padding: 20px;
    color: #666873;
}

.row li:hover {

    color: white;
}

.row img {
    padding: 10px;
    width: 134px;
    height: 70px;
    margin: 0 12px;
}

@media screen and (max-width:768px) {
    .col-md-3 .col-sm-6 {
        flex-direction: column;
    }

    .row {
        flex-direction: row;
    }

    #hmd {
        display: none;
    }
}

.text-xs-center {
    color: white;
}

hr {
    color: white !important;
    height: 3px !important;
}

@media screen and (max-width:1024px) {
    .row img {
        width: 124px;
    }
}