body{
    font-family: 'Oswald', sans-serif;

}


@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Oswald:wght@200;600&display=swap');


.Next {
    padding: 70px;
    background-color: #f5f8fa;
    ;
}

.How {
    padding: 70px;
}


.postjobcontainer {
    padding: 70px 0px;
}


.CONtent {
    padding: 70px;
}

/* .Subscribecontainer {
    padding: 70px;
} */

.Why {
    padding: 70px;
}

@media screen and (max-width:786px) {
    .navbar-brand img {
        margin: 2px;
    }

}

@media screen and (max-width:425px) {
    .navbar-brand img {
        margin: 16px;
    }

    .How {
        padding: 0px;
    }



    .postjobcontainer {
        padding: 0px;
    }


    .CONtent {
        padding: 0px;
    }

    /* .Subscribecontainer {
        padding: 70px;
    } */

    .Why {
        padding: 0px;
    }
}

@media screen and (max-width:320px) {
    .How {
        padding: 0px;
    }


    .postjobcontainer {
        padding: 0px;
    }


    .CONtent {
        padding: 0px;
    }

    /* .Subscribecontainer {
        padding: 70px;
    } */

    .Why {
        padding: 0px;
    }
}

.head{
    color: aliceblue !important;
}