.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}
*
{
  font-family: 'Roboto', sans-serif;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 300px;
  height: 50px;
  background: rgb(215, 209, 209);
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  position: absolute;
  left: 1px;
  min-width: 155px;
  /* max-width: 165px; */
  height: 50px;
  border-radius: 45px;
  transition: 0.38s;
  margin: 0%;
  /* background: #fff; */
  background: linear-gradient(45deg, #05abe0 0%, #1435ff 100%);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-button:before {
  /* content: "HIRE TALENT"; */
  color: white;
  /* transform: translate(100%,100%); */
  text-align: center;
}


.react-switch-button:after {
  /* content: "SHARE TALENT"; */
  color: white;
  /* transform: translate(100%,100%); */
  text-align: center;
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

#first {
  z-index: 1;
  padding: 24px;
  color: #777;
  align-items: center;
  margin-top: 12px;
}

#second {
  z-index: 1;
  padding: 24px;
  color: #777;
  margin-top: 12px;
}