
/* @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Oswald:wght@200;600&display=swap'); */


* {
    position: relative;
    font-family: 'Roboto', sans-serif;
    
}

/* #tricolor {
    margin-top: 30px;
} */

.owl-dots {
    visibility: hidden;
}
/* 
#cignex {
    margin-top: 45px;
} */


@media screen and (max-width:786px){
    
    
}


.carousel {
    display: block;
    /* width: 732px; */
    padding: 2% 9%;
    /* border-style: solid; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    margin-top: 50px;
}


.top-Text {
    /* border-style: solid; */
    margin-top: 70px;
    font-weight: 100;
    font-size: 1.4em;
    text-align: center;
}


.top-Text h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 5;
    font-size: 2.6em;
    color: black;
    display: inline-block;
    position: relative;
    z-index: 1;
    margin: 10;
}


.CONtent {
    /* border: 3px green solid; */
    background-color: rgb(236, 236, 236);
    padding: 10px 20px;
}


.carousel .item img {
    display: block;
    width: 70%;
   
    
    /* padding: 15px; */
}
