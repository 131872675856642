.nexttop {
    text-align: center;
}

/* .mb-5{
    margin-top: 12px;
    margin-bottom: 70px;
} */


.topnext-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 3em;
    color: black;
    position: relative;
    z-index: 1;
    margin: 0;
    text-align: center;
}

/* .topnext-text h2 {
    letter-spacing: 20px;
} */

.Nextfooter {
    display: flex;
    justify-content: space-around;


}

.text-center {
    color: black;
}

h4 {
    font-size: 50px;
    margin-top: 40px;
}

@media screen and (max-width:786px) {
    .Nextfooter {
        flex-direction: column;
        text-align: center;
    }

    .Nextfooter img {
        height: 30px;
        margin: 20px;
    }
}