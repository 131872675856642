.postjobcontainer {
    display: flex;
    justify-content: space-evenly;

}

@media screen and (max-width:786px) {
    .postjobcontainer {
        flex-direction: column;

    }
}
*
{
    font-family: 'Roboto', sans-serif;
}

p {
    margin-top: 14px;
    margin-bottom: 1rem;
    z-index: 1;
    padding: 0px 15px 0px 15px;
}

h1 {
    font-size: 1.9rem;
}

/* @media screen and (max-width:786px) {
    p {
        margin-top: 14px;
        margin-bottom: 1rem;
        z-index: 1;
        padding: 15px;
    }
} */


/* .postjobslidebtn {
    border-bottom: 1px solid #ddd;
} */

.postjobtext {
    text-align: center;
    width: 100%;
    padding: 0px 15px;

}

@media screen and (max-width:786px) {

    .postjobtext {
        /* padding: 5px; */
        margin-top: 0%;
        text-align: center;
    }

    .postjobimage {
        text-align: center;
        padding: 0px 15px;
    }

    .postjobbtn{
        /* width: 50px; */
    }

}

.btn {
    text-align: center;
    margin: 50px 0px 0px 0px;

}


.postjobbtn {
    background: transparent;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1em;
    text-transform: uppercase;
    color: black;
    border-radius: 3px;
    letter-spacing: 2px;
    border: 2px solid #9da9f5;
    padding: 10px 32px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    margin-top: 30px;

}

.buttons {
    text-align: center;
    margin: 53.2656px;
}

.postjobbtn:hover {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1em;
    text-transform: uppercase;
    color: white;
    border-radius: 3px;
    letter-spacing: 2px;
    padding: 10px 32px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    margin-top: 30px;
    background: #3b54ec;
    border-color: #3b54ec;

}

#photo {
    height: 80%;
    width: 95%;
    margin-top: 30px;
    padding: 5px;
    box-shadow: 0.1px 0.1px 0.1px 2px #ebe9e9;
}