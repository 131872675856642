@import url('https://fonts.googleapis.com/css2?family=Montserrat+Subrayada&display=swap');


* {
    margin: 0%;
    padding: 0%;
    font-family: 'Roboto', sans-serif;
}

.Header {
    height: 93vh;
    background-color: #003366;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 100% !important;
    background-image: url('./images/bg1.jpg');
}

.navbar {
    height: 80px;
    background-color: white;
    justify-content: center;
}

.container {
    display: flex;
    list-style-type: none;
    position: relative;
    text-align: center;
    color: #282828;
}


.container2 ul {
    display: flex;
    list-style: none;
    font-family: "Montserrat", sans-serif;
    width: 100%;

}

.container2 ul li {
    margin-left: 10px;
    margin-right: 10px;
}

.container2 li a {
    display: block;
    color: #282828;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 3px;
    padding: 24px, 12px;
}

.last {
    width: 250px;
    height: 50px;

}


/* #get_start {
    color: whitesmoke;
} */




.last button {

    width: 200px;
    height: 45px;
    background: #1435ff;
    color: white;
    /* border: 2px solid #1435ff; */
    border-radius: 3px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.last button {
    cursor: pointer;
}





.last button:hover {
    background-color: white;
    /* color: green; */
}


.lower {
    text-align: center;
    /* position: absolute;
    right: 240px;
    margin-right: auto; */
}

/* .lower h1 {
    color: white;
    font-size: 53px;
    font-size: 3.2rem;
    margin: 10px;
} */

.small {
    margin-top: 20px;
    text-align: center;

}

.small h4 {
    color: white;
    font-weight: 350;
    font-size: 1rem;
    margin-bottom: 25px;
}

.Headerbtn {
    background: #1435ff;
    color: white;
    border: 2px solid #1435ff;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 3px;
    margin: 3px;
    padding: 10px 32px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    position: relative;
    z-index: 1;
    font-size: 14px;
}



.Headerbtn:hover {
    color: #282828;
    background: #ffffff;
    border: 2px solid #1435ff;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 3px;
    margin: 3px;
    padding: 10px 32px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    position: relative;
    z-index: 1;
    font-size: 14px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.Headerbtns {
    background: #1435ff;
    color: white;
    border: 2px solid #1435ff;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 3px;
    margin: 3px;
    padding: 10px 32px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    position: relative;
    z-index: 1;
    font-size: 14px;
}



.Headerbtns:hover {
    color: #282828;
    background: #ffffff;
    border: 2px solid #1435ff;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 3px;
    margin: 3px;
    padding: 10px 32px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    position: relative;
    z-index: 1;
    font-size: 14px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.mt-5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: white;
    font-size: 3em;
    margin: 40px;
    text-align: center;
}

.nav-link {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #282828;
    font-size: 16px !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 21px;
    padding: 24px 12px;
    position: relative;
}

.container-fluid {
    padding: 0% !important;
}

#get_start {
    background-color: inherit;
    border: 2px solid white;
}

#get_hire {
    background-color: inherit;
    border: 2px solid white;
}

#get_start:hover {
    background-color: white;
    border: 2px solid white;
    color: blue;
}

#get_hire:hover {
    background-color: white;
    border: 2px solid white;
    color: blue;
}

.mb-2 {
    z-index: 1;
}

.border {
    border: 0px !important;
}

@media screen and (max-width:425px) {
    .p-5 {
        padding: 0.4rem !important;

    }

    .navbar {
        margin-left: 10px;
        margin-right: 10px;
    }

    .container-fluid {
        margin-top: -13px !important;
    }

    .navbar {
        height: 55px !important;
        margin-top: 10px;
    }
}

@media screen and (max-width:786px) {
    .Header {
        height: 80vh;
    }

    .lower h1 {
        font-family: "Montserrat", sans-serif;
        font-weight: bolder;
        color: white;
        margin: 0;
        text-align: center;
    }

    .lower {

        text-align: center;
        /* position: absolute; */
        /* right: 47px; */
        /* margin-left: 48px; */
    }

    .mt-5 {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: white;
        font-size: 2rem;
        margin: 40px;
        text-align: center;
    }

    .p-3 {
        padding: 0% !important;
    }

    .lower h1 {
        color: white;
        font-size: 2rem;
        margin: 10px;
    }

    .navbar {
        margin-top: 13px;
        height: 51px;
    }

    .container-fluid {
        z-index: 1;
    }

    .button {
        z-index: 0;
    }

    .Headerbtns {
        width: 99%;
    }

}