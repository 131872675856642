.heading {
    display: flex;
    justify-content: center;
    /* border: 3px solid black; */
}

.Why {
    background-color: rgb(235, 232, 232);
}

.buttons_why button{
    /* text-align: center; */
    /* margin-bottom: 20px; */
    /* top: 50%; */
    bottom: 20%;
    /* margin-right: 10px; */
    /* border: 3px solid black; */
}

#btn_why{
    margin-left: 10px;
}
*
{
    font-family: 'Roboto', sans-serif;
}



.Why_footer {

    display: flex;
    text-align: center;
    justify-content: space-evenly;
    padding: 55px;
}

@media screen and (max-width:786px) {

    #btnn_why{
        width: 115px;
        margin-right: 10px;
        padding-left: 25px;
    }
    .Why_footer {
        flex-direction: column;
        height: 50%;
    }

    .Why_post {
        height: 170px;
        margin-bottom: 20px;
    }

    .Why_get {
        height: 170px;
        margin-bottom: 20px;
    }

    .Why_deploy {
        height: 170px;
    }

    .heading {
        flex-direction: column;
        text-align: center;
    }

    .hello {
        text-align: -webkit-center;
        margin-bottom: 20px;
    }

}

.you {
    /* margin-bottom: 20px; */
    /* margin-right: 40px; */
    /* border: 3px solid black; */
}

.yes {
    /* margin-top: 20px; */
    /* margin-left: 20px; */
}

.Why_post {
    height: 170px;
    background-color:
        #225db9;
}

.Why_get {
    height: 170px;
    background-color:
        #225db9;
}

.Why_deploy {
    height: 170px;
    background-color:
        #225db9;
}

h2 {
    padding: 20px;
}

.Why_footer p1 {
    padding: 15px;
}

.Why_post h2 {
    color: white;
}

.Why_get h2 {
    color: white;
}

.Why_deploy h2 {
    color: white;
}

.Why_post p1 {
    color: white;
}

.Why_get p1 {
    color: white;
}

.Why_deploy p1 {
    color: white;
}