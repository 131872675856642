.Subscribecontainer {
    display: flex;
    justify-content: space-evenly;
    background: -webkit-linear-gradient(bottom, rgb(173, 216, 235) 0%, rgb(124, 178, 225) 40%, rgb(88, 151, 220) 100%);
}

@media screen and (max-width:786px) {
    .Subscribecontainer {
        flex-direction: column;

    }
}
*
{
    font-family: 'Roboto', sans-serif;
}

.Subscribetext {
    box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
    background: #fff;
    padding: 42px 36px;
    width: 36%;
    height: 50%;
    border-radius: 10px;
    text-align: center;
    margin: 90px 0px;
}

@media screen and (max-width:786px) {
    .Subscribetext {

        width: 70%;
        margin-left: 100px;
    }

    .Subscribeimage {
        text-align: center;
    }
}

@media screen and (max-width:425px) {
    .Subscribetext {
        width: 80%;
        padding: 20px 10px;
        margin-left: 30px;
    }
}

.Subscribebtn {
    background-color: #0063df;
    color: #fff;
    border: unset;
    border-radius: 50px;
    padding: 8px 15px;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.6s;
    /* display: flex; */
}

@media screen and (max-width:786px) {
    .Subscribebtn {
        margin-top: 10px;
    }
}

input {
    border: 2px solid #e4eff8;
    background-color: #e4eff8 !important;
    padding: 12px 24px;
    font-size: 13px !important;
    border-radius: 50px;
    /* border: 3px blue solid; */
    /* width: 250px; */
    outline: none;
    transition: 0.6s;
}


.Subscribeimage {
    margin-top: 10px;
}



#img {
    width: 280px;
    height: 400px;
    margin-top: 10px;
    padding: 5px;
}